import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import type { PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { getToken, setAdminRoles, setToken } from "../../utils/storage";
import { encrypt } from "../../utils/hash/password";
import { decodeToken } from "react-jwt";
import { instance } from "../../service/instance";
import adminRoles from "../../utils/validation/adminRoles";
import Swal from "sweetalert2";

export interface ILogin {
  email: string;
  password: string;
  showPass: boolean;
}
export interface ISubmitLogin {
  rejectMessage: string;
  isLoading: boolean;
  name: string;
  roleTitle: string;
  accesToken: string;
  adminRole: any;
  admUser: any;
  admRole: any;
  admUsrRole: any;
  admRoleAcs: any;
  admPageMaster: any;
  admPageAccess: any;
  eventLogs: any;
  appVersion: string;
  errorRoleType: string;
}

const initialState: ISubmitLogin = {
  rejectMessage: "",
  isLoading: false,
  name: "",
  roleTitle: "",
  accesToken: getToken(),
  adminRole: {},
  admUser: {},
  admRole: {},
  admUsrRole: {},
  admRoleAcs: {},
  admPageMaster: {},
  admPageAccess: {},
  eventLogs: {},
  appVersion: "",
  errorRoleType: "",
};

export const submitLogin = createAsyncThunk<any, any, { rejectValue: any }>(
  "user/submitLogin",
  async (dataToPost, { rejectWithValue, dispatch }) => {
    const { Email, Password } = dataToPost;
    try {
      let pasEnc = encrypt(Password);
      const resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_API}/user/login`,
        data: {
          Email,
          password: pasEnc,
        },
      });
      console.log(Email, pasEnc, "here isinya");
      console.log("response here ===> ", resp.data.data.access);
      const token = resp.data.data.access;
      // const userId: any = decodeToken(token);
      // userId.token = token;
      setToken(token);
      dispatch(getRoleType(''));
      return resp.data;
    } catch (error: any) {
      const errorMessage =  error?.response.data.msg.toLowerCase().includes('email')
      if(errorMessage){
        Swal.fire('Error Login', 'Invalid login email/password', 'error')
      }else {
        Swal.fire('Error Login', error?.response.data.msg, 'error')
      }
      throw rejectWithValue(error);
    }
  }
);

export const getRoleType = createAsyncThunk<any, any, { rejectValue: any }>(
  "auth/get-role",
  async (_, { rejectWithValue }) => {
    try {
      const resp: any = await instance.get(`/user/auth-data/admin`
      // , {
      //   headers: { Authorization: `Bearer ${data.token}` },
      // }
      );
      const respDataStr = resp.data.data;
      const admUser = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "user-admin")
      const admRoles = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "admin-roles")
      const admUsrRoles = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "admin-user-roles")
      const admRolesAcs = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "user-roles-access")
      const admPageMstr = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "admin-page-master")
      const admPageAcs = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "admin-page-access")
      const eventsLog = respDataStr.admin_page_access.find((obj: any) => obj.pge_master_access_url.split("/").pop() === "events-log")
      return {respDataStr, admUser, admRoles, admUsrRoles, admRolesAcs, admPageMstr, admPageAcs, eventsLog };
    } catch (err) {
      Swal.fire('Error Login', 'Role has not been assign for this module', 'error')
      throw rejectWithValue(err);
    }
  }
);

export const getAppVersion = createAsyncThunk<any, any, { rejectValue: any }>(
  "app-version",
  async ({}, { rejectWithValue }) => {
    try {
      const resp: any = await instance.get(`/admin-app-version/latest`);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeRejectMassage: (state) => {
      state.rejectMessage = "";
    },
    removeToken: (state) => {
      state.accesToken = "";
    },
    changeLoginLoading: (state) => {
      state.isLoading = false
    },
    deleteErrMsg: (state) => {
      state.errorRoleType = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.name = action.payload.name;
        state.accesToken = action.payload.data.access;
        state.roleTitle = action.payload.role_title;
      })
      .addCase(submitLogin.rejected, (state, action) => {
        state.rejectMessage = action.payload.response.data.err_details;
        state.isLoading = false;
      })
      .addCase(getRoleType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleType.fulfilled, (state, action) => {
        const {respDataStr, admUser, admRoles, admUsrRoles, admRolesAcs, admPageMstr, admPageAcs, eventsLog } = action.payload 
        state.isLoading = false;
        state.adminRole = respDataStr
        state.admUser = admUser
        state.admRole = admRoles
        state.admUsrRole = admUsrRoles
        state.admRoleAcs = admRolesAcs
        state.admPageMaster = admPageMstr
        state.admPageAccess = admPageAcs
        state.eventLogs = eventsLog
      })
      .addCase(getRoleType.rejected, (state, action) => {
        state.isLoading = false;
        state.errorRoleType = action.payload.response.data.msg
      })
      .addCase(getAppVersion.pending, (state) => {
      })
      .addCase(getAppVersion.fulfilled, (state, action) => {
        state.appVersion = action.payload.data.uc_admin_app_version_number;
      })
      .addCase(getAppVersion.rejected, (state) => {
      })
  },
});

export const { removeRejectMassage, removeToken, changeLoginLoading, deleteErrMsg } = counterSlice.actions;

export default counterSlice.reducer;
